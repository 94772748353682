import { useAtom, useAtomValue } from 'jotai';
import type { SetStateAction } from 'jotai';
import { placesAtom, placesLatLngsSelector } from './states';
import type { LatLng, Place } from './types';
import { useEnvironmentAPI, usePlacesAPI } from '@api/fms';
import { useCallback, useState } from 'react';
import { environmentAtom } from '../environment/states';
import { useAtomCallback } from 'jotai/utils';
import { useMount } from 'react-use';

export const usePlaces = (): {
  places: Place[];
  setPlaces: SetAtom<[SetStateAction<Place[]>], void>;
  placesLatLngs: LatLng[];
} => {
  const [places, setPlaces] = useAtom(placesAtom);
  const placesLatLngs = useAtomValue(placesLatLngsSelector);

  return {
    places,
    setPlaces,
    placesLatLngs,
  };
};

export const useLoadPlaces = () => {
  const { getEnvironment } = useEnvironmentAPI();
  const { getPlaces } = usePlacesAPI();
  const [isLoaded, setIsLoaded] = useState(false);

  const loadPlaces = useAtomCallback(
    useCallback(
      async (get, set) => {
        const environment = get(environmentAtom);
        if (!environment) {
          setIsLoaded(true);
          return;
        }
        const environmentRes = await getEnvironment(environment.environment_id);
        if (!environmentRes) {
          setIsLoaded(true);
          return;
        }
        set(environmentAtom, environmentRes);

        const placesRes = await getPlaces();
        set(placesAtom, placesRes);
        setIsLoaded(true);
      },
      [getEnvironment, getPlaces],
    ),
  );

  useMount(() => {
    loadPlaces();
  });

  return {
    isLoaded,
  };
};
