import React from 'react';
import type { GoogleMapProps } from '@react-google-maps/api';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import { Box, Typography, styled, Alert, AlertTitle } from '@mui/material';
import { keyframes } from '@emotion/react';
import type { Library } from '@googlemaps/js-api-loader';
import { hasPlacesSelector, isPlacesLoadedAtom } from '@data/fms/place/states';
import { useAtomValue } from 'jotai';

const styles: google.maps.MapTypeStyle[] = [
  {
    stylers: [
      {
        color: '#73A5A5',
      },
      {
        saturation: 65,
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'geometry.fill',
    stylers: [
      {
        saturation: -100,
      },
    ],
  },
  {
    elementType: 'labels',
    stylers: [
      {
        color: '#4F4DFF',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#000000',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative',
    stylers: [
      {
        color: '#949494',
      },
    ],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#333333',
      },
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'landscape',
    stylers: [
      {
        color: '#BFBFBF',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#92B4CE',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#121212',
      },
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'landscape.man_made',
    stylers: [
      {
        color: '#CFCFCF',
      },
    ],
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#3D3D3D',
      },
    ],
  },
  {
    featureType: 'landscape.natural',
    stylers: [
      {
        color: '#ABC4BE',
      },
    ],
  },
  {
    featureType: 'poi.business',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.medical',
    elementType: 'geometry',
    stylers: [
      {
        color: '#FFD6D6',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'poi.park',
    stylers: [
      {
        color: '#9CD3C0',
      },
    ],
  },
  {
    featureType: 'poi.school',
    stylers: [
      {
        color: '#DEDEDE',
      },
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    stylers: [
      {
        weight: 0.5,
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#FAFAFA',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#FFFFFF',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [
      {
        saturation: -100,
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#001EFF',
      },
    ],
  },
  {
    featureType: 'road.highway',
    stylers: [
      {
        color: '#F5F5F5"',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#E3A17D',
      },
      {
        lightness: 10,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#527784',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#1C1C1C',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'labels.icon',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 25,
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#FFFFFF',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#FFFFFF',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'labels.text',
    stylers: [
      {
        color: '#737373',
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#F2F2F2',
      },
    ],
  },
  {
    featureType: 'water',
    stylers: [
      {
        color: '#BECFDF',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#999999',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#464F62',
      },
    ],
  },
];

const NoPlaces: React.FC = () => {
  const { t } = useTranslation();
  const isPlacesLoaded = useAtomValue(isPlacesLoadedAtom);
  const hasPlaces = useAtomValue(hasPlacesSelector);

  if (!isPlacesLoaded || hasPlaces) return null;

  return (
    <NoPlacesWrapper>
      <Box maxWidth={400}>
        <Alert severity="warning">
          <AlertTitle>{t('common.fms.map.no_stop_points.title')}</AlertTitle>
          <Typography>
            {t('common.fms.map.no_stop_points.description')}
          </Typography>
        </Alert>
      </Box>
    </NoPlacesWrapper>
  );
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const NoPlacesWrapper = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  animation: ${fadeIn} 0.1s 0.2s both;
`;

type Props = GoogleMapProps & {
  options?: google.maps.MapOptions;
  showNoPlaces?: boolean;
};

const libraries: Library[] = ['visualization'];

const MapContainer: React.FC<Props> = ({
  options = {},
  showNoPlaces = false,
  children,
  ...rest
}: Props) => {
  const { i18n } = useTranslation();
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY!,
    language: i18n.language,
    // NOTE: バージョン情報 (https://developers.google.com/maps/documentation/javascript/versions)
    version: 'quarterly',
    libraries,
  });

  if (!isLoaded) {
    return <Box sx={{ width: '100%', height: '100%' }} />;
  }

  if (loadError) {
    return <Box>Map can not be loaded.</Box>;
  }

  return (
    <Box width="100%" height="100%" position="relative">
      <GoogleMap
        zoom={18}
        mapContainerStyle={{ width: '100%', height: '100%', outline: 'none' }}
        options={{
          fullscreenControl: false,
          streetViewControl: false,
          rotateControl: false,
          tilt: 0,
          styles,
          ...options,
        }}
        {...rest}
      >
        {children}
      </GoogleMap>
      {showNoPlaces && <NoPlaces />}
    </Box>
  );
};

export default React.memo(MapContainer);
