import { css } from '@emotion/react';
import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';

const useGlobalStyle = () => {
  const theme = useTheme();

  const globalStyle = useMemo(
    () => css`
      html,
      body,
      #root {
        height: 100%;
      }

      body {
        min-width: 1440px;
        background: ${theme.body.background};
        @media screen and (min-width: ${theme.breakpoints.values.lg}px) {
          overflow-x: hidden;
        }
      }

      a {
        text-decoration: none;
      }

      .MuiCardHeader-action .MuiIconButton-root {
        padding: 4px;
        width: 28px;
        height: 28px;
      }

      // google maps
      .infoBox {
        overflow: visible !important;
      }

      // notistack
      .notistack-SnackbarContainer {
        top: 66px;
        white-space: pre-line;
        max-height: calc(100% - 114px);
        pointer-events: none;
        z-index: 1500;

        body[data-maintenance='true'] & {
          top: 104px;
        }
      }
    `,
    [theme],
  );

  return globalStyle;
};

export default useGlobalStyle;
