import {
  useRouteAPI,
  useFMSWebSocket,
} from '@api/fms';
import { useSettings } from '@data/settings/hooks';
import isNullOrUndefined, { notNull } from '@utils/isNullOrUndefined';
import {
  isConnected,
  isDisconnected,
  isShutdown,
} from '@data/fms/vehicle/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePrevious, useUnmount } from 'react-use';
import type { Place } from '../place/types';
import type { Schedule } from '../schedule/types';
import type { Vehicle } from '../vehicle/types';
import type { TaskRoute } from './types';
import { taskRouteSortOrder } from './constants';

export const useActiveScheduleAndRoute = (
  vehicle: Vehicle | null | undefined,
) => {
  const prevVehicle = usePrevious(vehicle);
  const {
    createSocket: updateVehicleActiveSchedule,
    data: updatedVehicleActiveSchedule,
    closeSocket: closeVehicleActiveScheduleSocket,
  } = useFMSWebSocket({ channel: 'vehicleActiveSchedule' });
  const { getRoute } = useRouteAPI();
  const { settings } = useSettings();
  const [activeSchedule, setActiveSchedule] = useState<Schedule | null>(null);
  const [routes, setRoutes] = useState<(TaskRoute | null)[]>([]);
  const [originPoint, setOriginPoint] = useState<Place | null>(null);
  const [destinationPoint, setDestinationPoint] = useState<Place | null>(null);

  const getActiveScheduleRoute = useCallback(async () => {
    if (
      isNullOrUndefined(vehicle) ||
      isConnected(vehicle) ||
      isDisconnected(vehicle) ||
      isShutdown(vehicle)
    ) {
      setActiveSchedule(null);
      setRoutes([]);
      setOriginPoint(null);
      setDestinationPoint(null);
      return;
    }

    try {
      setActiveSchedule(null);
      setRoutes([]);
      updateVehicleActiveSchedule(vehicle.vehicle_id);
    } catch {
      setActiveSchedule(null);
      setRoutes([]);
      setOriginPoint(null);
      setDestinationPoint(null);
    }

    if (!settings.vehicle.displayRoute) {
      setRoutes([]);
      setOriginPoint(null);
      setDestinationPoint(null);
    }
  }, [settings.vehicle.displayRoute, vehicle, updateVehicleActiveSchedule]);

  const resetScheduleAndRoute = useCallback(
    (doVehicleChange?: boolean) => {
      closeVehicleActiveScheduleSocket(doVehicleChange);
      setRoutes([]);
      setActiveSchedule(null);
      setOriginPoint(null);
      setDestinationPoint(null);
    },
    [closeVehicleActiveScheduleSocket],
  );

  const sortedTaskRoutesForPolylines = useMemo(() => {
    const filtered = routes.filter(notNull);
    const sorted = filtered.sort(
      (a, b) =>
        taskRouteSortOrder.indexOf(a.status) -
        taskRouteSortOrder.indexOf(b.status),
    );
    // NOTE: 配列の後から上にくるため reverse する
    return sorted.reverse();
  }, [routes]);

  useEffect(() => {
    if (
      isNullOrUndefined(activeSchedule) ||
      activeSchedule.status === 'canceled' ||
      activeSchedule.status === 'aborted' ||
      activeSchedule.status === 'disabled' ||
      activeSchedule.status === 'done' ||
      isNullOrUndefined(activeSchedule.tasks)
    ) {
      setRoutes([]);
      setActiveSchedule(null);
      setOriginPoint(null);
      setDestinationPoint(null);
    }
  }, [activeSchedule]);

  useEffect(() => {
    if (settings.vehicle.displayRoute && activeSchedule?.status === 'doing') {
      (async () => {
        if (isNullOrUndefined(activeSchedule.tasks)) return;
        const routes = await Promise.all(
          activeSchedule.tasks.map(async (task) => {
            if (
              isNullOrUndefined(task.route_ids) ||
              task.route_ids.length === 0
            )
              return null;
            const routeRes = await getRoute(task.route_ids);
            return {
              status: task.status,
              routes: routeRes,
            };
          }),
        );

        const originRoute = routes[0];
        const originRouteFirst = originRoute?.routes[0];
        const destinationRoute = routes[routes.length - 1];
        const destinationRouteFirst = destinationRoute?.routes[0];

        setRoutes(routes);
        setOriginPoint(originRouteFirst?.points[0] ?? null);
        setDestinationPoint(
          !isNullOrUndefined(destinationRouteFirst)
            ? destinationRouteFirst.points[
                destinationRouteFirst.points.length - 1
              ]
            : null,
        );
      })();
    } else {
      setRoutes([]);
      setOriginPoint(null);
      setDestinationPoint(null);
    }
  }, [activeSchedule, getRoute, settings.vehicle.displayRoute]);

  useEffect(() => {
    if (
      (isNullOrUndefined(prevVehicle) && !isNullOrUndefined(vehicle)) ||
      (prevVehicle?.vehicle_id !== vehicle?.vehicle_id &&
        !isNullOrUndefined(vehicle))
    ) {
      resetScheduleAndRoute(true);
      // 選択車両変更のタイミングでリクエスト
      getActiveScheduleRoute();
    } else if (isNullOrUndefined(vehicle)) {
      // 車両未選択の場合はルートを表示しない
      resetScheduleAndRoute();
    }
  }, [
    vehicle,
    prevVehicle,
    getActiveScheduleRoute,
    closeVehicleActiveScheduleSocket,
    resetScheduleAndRoute,
  ]);

  useEffect(() => {
    setActiveSchedule(updatedVehicleActiveSchedule);
  }, [updatedVehicleActiveSchedule]);

  useUnmount(() => {
    closeVehicleActiveScheduleSocket();
  });

  return {
    activeSchedule,
    routes,
    originPoint,
    destinationPoint,
    getActiveScheduleRoute,
    resetScheduleAndRoute,
    sortedTaskRoutesForPolylines,
  };
};
