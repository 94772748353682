import { useVehicleAPI, useFMSWebSocket } from '@api/fms';
import { useAtom, useSetAtom } from 'jotai';
import { useAtomCallback } from 'jotai/utils';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useMount } from 'react-use';
import store from 'store2';
import { storedVehicleIdAtom, vehiclesAtom } from './states';
import type {
  Vehicle,
  VehicleIndividualParameter,
  VehicleMetadata,
} from './types';

export const useSelectedVehicleId = () => {
  const { storedVehicleId, setStoredVehicleId } = useStoredVehicleId();
  const params = useParams<{ vehicle_id?: string }>();

  const selectedVehicleId = useMemo(() => {
    return params.vehicle_id ?? storedVehicleId ?? undefined;
  }, [params.vehicle_id, storedVehicleId]);

  useMount(() => {
    if (params.vehicle_id) {
      setStoredVehicleId(params.vehicle_id);
    }
  });

  return {
    selectedVehicleId,
  };
};

export const useStoredVehicleId = () => {
  const [storedVehicleId, setStoredVehicleId] = useAtom(storedVehicleIdAtom);

  useEffect(() => {
    if (storedVehicleId) {
      store.local.set('vehicle_id', storedVehicleId);
      return;
    }
    store.local.remove('vehicle_id');
  }, [storedVehicleId]);

  return {
    storedVehicleId,
    setStoredVehicleId,
  };
};

export const useVehiclesDataWebSocket = () => {
  const {
    createSocket: createVehiclesTelemetrySocket,
    data: updatedVehiclesTelemetry,
    closeSocket: closeVehiclesTelemetrySocket,
  } = useFMSWebSocket({ channel: 'environmentVehiclesTelemetry' });
  const {
    createSocket: createVehiclesMetadataSocket,
    data: updatedVehiclesMetadata,
    closeSocket: closeVehiclesMetadataSocket,
  } = useFMSWebSocket({ channel: 'environmentVehiclesMetadataUpdates' });
  const setVehicles = useSetAtom(vehiclesAtom);
  const { getVehicle } = useVehicleAPI();
  const tempCreatedVehicleIds = useRef<string[]>([]);

  /**
   * 作成された車両を一覧に追加
   */
  const addCreatedVehicle = useAtomCallback(
    useCallback(
      async (get, _, updatedData: VehicleMetadata) => {
        // すでに車両情報を取得中の場合は中断
        if (tempCreatedVehicleIds.current.includes(updatedData.vehicle_id)) {
          return;
        }
        // 取得中の車両IDを削除
        const deleteTempId = () => {
          tempCreatedVehicleIds.current = tempCreatedVehicleIds.current.filter(
            (id) => id !== updatedData.vehicle_id,
          );
        };
        tempCreatedVehicleIds.current.push(updatedData.vehicle_id);

        const vehicles = get(vehiclesAtom);
        const foundVehicle = vehicles.find(
          (vehicle) => vehicle.vehicle_id === updatedData.vehicle_id,
        );
        if (foundVehicle) {
          // すでに一覧に作成済み車両がある場合は中断
          deleteTempId();
          return;
        }
        // 一覧にない場合は車両情報を取得
        const res = await getVehicle(updatedData.vehicle_id);
        if (!res) {
          // 車両情報を取得できない場合は中断
          deleteTempId();
          return;
        }
        setVehicles((prevState) =>
          [...prevState, res].sort((a: Vehicle, b: Vehicle) =>
            a.vehicle_name < b.vehicle_name ? -1 : 1,
          ),
        );
        deleteTempId();
      },
      [getVehicle, setVehicles],
    ),
  );

  /**
   * 削除された車両を一覧から取り除く
   */
  const removeDeletedVehicle = useAtomCallback(
    useCallback(
      (get, _, updatedData: VehicleMetadata) => {
        const vehicles = get(vehiclesAtom);
        const foundVehicle = vehicles.find(
          (vehicle) => vehicle.vehicle_id === updatedData.vehicle_id,
        );
        // すでに一覧に削除済み車両がない場合は中断
        if (!foundVehicle) return;
        // ある場合は一覧から削除
        setVehicles((prevState) =>
          prevState.filter(
            (vehicle) => vehicle.vehicle_id !== updatedData.vehicle_id,
          ),
        );
      },
      [setVehicles],
    ),
  );

  /**
   * 更新された VehicleMetaData を既存のデータにマージして返す
   */
  const getMergedVehicleMetadata = useCallback(
    (vehicle: Vehicle, updatedData: VehicleMetadata): Vehicle => {

      const getAreaMap = () => updatedData.area_map ?? vehicle.area_map;

      const getCatalog = () => updatedData.catalog ?? vehicle.catalog;

      const getMergedCalibrationParam = () => {
        if (!updatedData.calibration_parameter) {
          // 更新情報に calibration_parameter が無い場合
          return vehicle.calibration_parameter;
        }

        // 更新情報に calibration_parameter がある場合
        const newParameterCandidateStatuses =
          updatedData.calibration_parameter?.new_parameter_candidate_statuses;

        const calibrationParam: VehicleIndividualParameter = {
          ...vehicle.calibration_parameter,
          ...updatedData.calibration_parameter,
        };
        if (!newParameterCandidateStatuses) {
          return calibrationParam;
        }
        if (!newParameterCandidateStatuses.accel_brake_map) {
          // 更新情報に calibration_parameter.new_parameter_candidate_statuses がある場合
          return {
            ...calibrationParam,
            new_parameter_candidate_statuses: {
              ...vehicle.calibration_parameter.new_parameter_candidate_statuses,
              ...newParameterCandidateStatuses,
            },
          };
        }
        // 更新情報に calibration_parameter.new_parameter_candidate_statuses.accel_brake_map がある場合
        return {
          ...calibrationParam,
          new_parameter_candidate_statuses: {
            ...calibrationParam.new_parameter_candidate_statuses,
            accel_brake_map: {
              ...vehicle.calibration_parameter.new_parameter_candidate_statuses
                .accel_brake_map,
              ...newParameterCandidateStatuses.accel_brake_map,
            },
          },
        };
      };
      return {
        ...vehicle,
        ...updatedData,
        area_map: {
          ...vehicle.area_map,
          ...getAreaMap(),
        },
        catalog: {
          ...vehicle.catalog,
          ...getCatalog(),
        },
        calibration_parameter: getMergedCalibrationParam(),
      };
    },
    [],
  );

  /**
   * Telemetry 更新時の処理
   */
  useEffect(() => {
    if (!updatedVehiclesTelemetry) return;
    setVehicles((prevState) => {
      return prevState.map((vehicle) =>
        vehicle.vehicle_id === updatedVehiclesTelemetry.vehicle_id
          ? {
              ...vehicle,
              telemetry: {
                ...vehicle.telemetry,
                ...updatedVehiclesTelemetry,
              },
            }
          : vehicle,
      );
    });
  }, [updatedVehiclesTelemetry, setVehicles]);

  /**
   * Metadata 更新時の処理
   */
  useEffect(() => {
    if (!updatedVehiclesMetadata) return;
    const updatesType = updatedVehiclesMetadata.updates_type;
    const updatedData = updatedVehiclesMetadata.vehicle_data;
    if (
      updatesType === 'CREATED' ||
      // NOTE: 稀に FSim の場合 CREATED と MODIFIED がミリ秒単位で同じ時刻で渡される時があり、CREATED が拾えない場合を考慮する
      (updatesType === 'MODIFIED' && updatedData.creation_status === 'ready')
    ) {
      // 作成時
      addCreatedVehicle(updatedData);
      return;
    }
    if (updatesType === 'DELETED') {
      // 削除時
      removeDeletedVehicle(updatedData);
      return;
    }
    // 更新時
    setVehicles((prevState) =>
      prevState.map((vehicle) =>
        vehicle.vehicle_id === updatedData.vehicle_id
          ? getMergedVehicleMetadata(vehicle, updatedData)
          : vehicle,
      ),
    );
  }, [
    updatedVehiclesMetadata,
    getMergedVehicleMetadata,
    setVehicles,
    addCreatedVehicle,
    removeDeletedVehicle,
  ]);

  return {
    createVehiclesTelemetrySocket,
    closeVehiclesTelemetrySocket,
    createVehiclesMetadataSocket,
    closeVehiclesMetadataSocket,
  };
};
